import React from 'react'
import imgJpeg from '../../images/mainbanner.jpg'
// import imgWebp from '../../images/mainbanner.webp'
import '../../styles/components/mainbanner.css'

const MainBanner = () => {
  return (
    <>
      <div className="bannerContainer" style={{ backgroundImage: `url(${imgJpeg})`}}>
        <div className="overlay-grey"></div>
        <h1><span>BIZEPS</span><span>MEDIZIN</span><span>INITIATIVE</span></h1>
      </div>
    </>
  )
}

export default MainBanner