import React from 'react'
import '../../styles/components/cardscontainer.css'
import Card from './Card'
import trainingImg from '../../images/card-training.jpg'
import physioImg from '../../images/card-physio.jpg'
import workshopImg from '../../images/card-workshop.jpg'

const CardsContainer = ({ lang }) => {
  const useCopy = {
    de: {
      slogan: 'Fakten Fitness Physiotherapie',
      title: 'Es ist an der Zeit Verantwortung zu übernehmen.',
      training: {
        title: 'training',
        subheader: 'Du willst fitter, robuster, beweglicher, athletischer werden?'
      },
      physio: {
        title: 'physiotherapie',
        subheader: 'Du willst Schmerzen und Verletzungen hinter dir lassen?'
      },
      workshop: {
        title: 'vorträge',
        subheader: 'Lerne deine körperliche Gesundheit zu steuern.'
      }
    },
    en: {
      slogan: 'Facts Fitness Physiotherapy',
      title: "It's time to take responsibility.",
      training: {
        title: 'training',
        subheader: 'Do you want to become fitter, more robust, more agile, more athletic?'
      },
      physio: {
        title: 'physiotherapy',
        subheader: 'Do you want to leave pain and injuries behind?'
      },
      workshop: {
        title: 'workshop',
        subheader: 'Learn how to navigate your physical health.'
      }
    }
  }
  return (
    <div className="section-cards light-gradient-bg">
      <p className="home-slogan">{useCopy[lang].slogan}</p>
      <h2>{useCopy[lang].title}</h2>
      <div className="cards">
        <Card text={useCopy[lang].training} img={trainingImg} lang={lang} linkto='/fitness'/>
        <Card text={useCopy[lang].physio} img={physioImg} lang={lang} linkto='/physiotherapy'/>
        <Card text={useCopy[lang].workshop} img={workshopImg} lang={lang} linkto='/workshop'/>
      </div>
      
    </div>
  )
}

export default CardsContainer
