import React from 'react'
import { Link, navigate } from 'gatsby'
import '../../styles/components/card.css'

const Card = ({ text, img, lang, linkto }) => {
  const cta = {
    de: 'Erfahre Mehr',
    en: 'Learn More'
  }
  const alt = 'about ' + text.title;
  return (
    <div className="learnMore-card">
      <img src={img} alt={alt} onClick={() => {navigate(linkto)}} onKeyDown={() => {navigate(linkto)}} role="presentation"/>
      <h3>{text.title}</h3>
      <p className="card-subheader">{text.subheader}</p>
      <Link to={linkto}>{cta[lang]}</Link>
    </div>
  )
}

export default Card