import React from 'react'
import Layout from '../components/Layout'
import MainBanner from '../components/index/MainBanner'
import CardsContainer from '../components/index/CardsContainer'
import BottomBanner from '../components/index/BottomBanner'

const IndexPage = () => {
  return (
    <Layout pageTitle="Bizeps Medizin Initiative">
      <MainBanner />
      <CardsContainer lang />
      <BottomBanner lang />
    </Layout>
  )
}

export default IndexPage