import React from 'react'
import '../../styles/components/bottombanner.css'
import bottomBanner from '../../images/bottombanner.jpg'
import { navigate } from 'gatsby'

const BottomBanner = ({ lang }) => {
  const useCopy = {
    de: {
      text1: 'Was ist die',
      cta: 'über uns'
    },
    en: {
      text1: 'What is the',
      cta: 'about us'
    }
  }
  return (
    <div className="banner-bottom">
      <h2 className="font-grey">{useCopy[lang].text1}</h2>
      <div>
        <h2 className="brand-name">BIZEPS MEDIZIN INITIATIVE</h2>
        <div className="cta-pink" onClick={() => {navigate('/about')}} onKeyDown={() => {navigate('/about')}} role="button" tabIndex='0'><p>{useCopy[lang].cta} →</p></div>
      </div>
      <div className="banner-container" style={{ backgroundImage: `url(${bottomBanner})`}}>
      </div>
    </div>
  )
}

export default BottomBanner